import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84')
];

export const server_loads = [45,2,5,7,9,11,13,37,38,15,17,47,18,20,43,22,23,35,25,27,29,32,3];

export const dictionary = {
		"/(personal-site)": [~82,[45],[46]],
		"/(app)/analytics": [50,[2,5],[,6]],
		"/(app)/dashboard": [~51,[2,7],[,8]],
		"/(app)/inbox": [52,[2]],
		"/(app)/listings/create": [~53,[2,9],[,10]],
		"/(app)/listings/edit/[listing_id]": [~54,[2,11],[,12]],
		"/(app)/listings/new": [~55,[2,13],[,14]],
		"/(auth)/login": [~77,[37,38],[,39]],
		"/(auth)/login/change-password": [~78,[37,38],[,39]],
		"/(auth)/login/forgot-password": [~79,[37,38,40],[,39]],
		"/(app)/preview/profile": [~56,[2,15],[,16]],
		"/(app)/profile-sharing": [57,[2,17]],
		"/(app)/property-matching": [59,[2]],
		"/(app)/property-matching/posts": [~60,[2]],
		"/(personal-site)/property-view/[listing_id]": [~83,[45,47],[46,48]],
		"/(app)/property/[listing_id]": [~58,[2,18],[,19]],
		"/sentry-example": [84],
		"/(app)/settings/[user_id]": [~61,[2,20],[,21]],
		"/(app)/settings/[user_id]/license": [~62,[2,20],[,21]],
		"/(app)/settings/[user_id]/profile": [~63,[2,20],[,21]],
		"/(app)/settings/[user_id]/social-profiles": [~64,[2,20],[,21]],
		"/(app)/settings/[user_id]/subscriptions": [~65,[2,20],[,21]],
		"/(auth)/signup": [~80,[37,41],[,42]],
		"/(auth)/signup/setup-profile": [~81,[37,41,43],[,42,44]],
		"/(app)/subscribe": [~66,[2,22]],
		"/(app)/success": [~67,[2]],
		"/(app)/teams": [~68,[2,23],[,24]],
		"/(app)/teams/accept-invite/[invite_id]": [~74,[2,23,34],[,24]],
		"/(app)/teams/create": [~75,[2,23,35],[,24,36]],
		"/(app)/teams/find-teams": [76,[2,23],[,24]],
		"/(app)/teams/[team_id]": [69,[2,23,25],[,24,26]],
		"/(app)/teams/[team_id]/create-listing": [~70,[2,23,25,27],[,24,26,28]],
		"/(app)/teams/[team_id]/edit-listing/[listing_id]": [~71,[2,23,25,29],[,24,26,30]],
		"/(app)/teams/[team_id]/settings": [~72,[2,23,25,31],[,24,26]],
		"/(app)/teams/[team_id]/settings/members": [~73,[2,23,25,31,32],[,24,26,,33]],
		"/(app)/[broker_id]": [~49,[2,3],[,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';